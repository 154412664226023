import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-1px">
          <CFImage src={mobileAbout} w="100%" alt="Sushi Today About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column center>
          <CFImage w="100%" src={about} alt="Sushi Today Logo" zIndex={98} />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="120vh"
          maxHeight="720px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView w="100%" column justifyStart alignCenter bg="rgba(0,0,0,0.9)">
            <CFView pulsate mv="10px" textCenter>
              <OrderPickupButton />
            </CFView>
            <CFView textCenter mb="10px">
              <ViewMenuButton />
            </CFView>
            <CFView textCenter mb="10px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView column center bg="rgba(0,0,0,0.7)" ph="100px" pv="5px">
            {/* <CFView bold white style={{ fontSize: 28 }}>
              WELCOME TO SUSHI TODAY!
            </CFView> */}
            <CFView row center>
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView ml="15px" mt="5px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
